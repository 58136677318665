<template>
<div class="mouduleTable">
    <el-table
        :data="tableData"
        v-el-table-infinite-scroll='load'
        row-class-name='tableRow'
        :infinite-scroll-immediate="false"
        infinite-scroll-distance="'50px'"
        @cell-mouse-enter='f_cellMouseEnter'
        @cell-mouse-leave='f_cellMouseLeave'
        :cell-style='{color:"#000"}'
        border
        :height="`100%`"
        class="my-table"
        style="width: 100%">
        <template v-for="(item, index) in tableHead"  v-if="tableData.length>0">
            <el-table-column
                v-if="!item.slot"
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                :min-width="item.minWidth"
                :show-overflow-tooltip='true'
                :key="index"
            >
            </el-table-column>
            <el-table-column
                v-else
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                :min-width="item.minWidth"
                :show-overflow-tooltip='true'
                :key="index"
            >
            <template slot-scope="scope" >
               <slot :name="item.slot" :scope="scope" />
            </template>
            </el-table-column>
        </template>
    </el-table>
</div>
  
</template>

<script>
export default {
    props:{
        tableData:{
            type:Array,
            default:()=>{
                return []
            }
        },
        tableHead: {
            // 表头数据
            type: Array,
            default: () => []
        },
    },
    components: {
    },
    methods:{
        f_cellMouseEnter(row, column, cell, event){
            this.$emit('f_cellMouseEnter',row)
        },
        f_cellMouseLeave(row, column, cell, event){
            this.$emit('f_cellMouseLeave',row)
        },
        load(){
            this.$emit('load','')
        },
        handleSizeChange(){},
        handleCurrentChange(){}
    },
    mounted(){
    }
}
</script>

<style lang='scss'>
.mouduleTable{
    position: relative;
    .tableRow{
        height: 80px;
    }
    .my-table.el-table .el-table__body-wrapper {
        height: calc(100vh - 240px) !important;
        overflow-x: auto;
        overflow-y:hidden ;
    }
    .el-pagination{
        position: absolute;
        right: 10px;
        bottom: 0px;
    }
}

</style>