<template>
    <div class="similarAudience" v-loading="loading"  element-loading-text="正在创建中"
    element-loading-spinner="el-icon-loading">
        <div :class="['saLeft', shrink?'spread':'harvest']">
            <div :class="shrink?'unwind':'shrink'" @click="f_shrink">
                <span v-show="shrink"><i class="el-icon-d-arrow-right" ></i></span> 
                <span v-show="!shrink"><i class="el-icon-d-arrow-left" ></i></span>
            </div>
            <div class="leftTip">
                <h4><i class="el-icon-magic-stick"></i> 类似受众提示</h4>
                <h3>寻找与现有客户相似的人群</h3>
                <p>与现有客户相似的用户也可能对你的业务感兴趣，通过类似受众，你可以寻找这类新受众并向他们营销。由你选择源受众，我们会确定其中用户的共同特征。然后，我们会根据你选择的地区和需要的受众规模寻找与源受众相似的用户。</p>
                <a href="#">详细了解</a>
                <h3>寻找与现有客户相似的人群</h3>
                <p>你还可以根据价值最高的客户寻找相似的新受众并向他们营销。选择包含客户终生价值 (LTV) 的源受众时，我们会使用这一信息，根据对你的业务而言最有价值的客户寻找具有相似特征的新受众。</p>
                <p>若要创建价值导向型类似受众，需要上传包含终生价值栏的自定义受众数据文件，或者设置 Pixel 像素代码或应用，来捕获包含价值的事件，如购物。</p>
                <a href="#">详细了解</a>
            </div>
        </div>
        <div class="saRight">
            <div class="saRigBox">
                <span class="serial">1</span>
                <div>
                     <h3>
                        选择类似受众来源 
                        <el-popover
                            placement="right"
                            width="200"
                            trigger="hover"
                            content="你选择的来源将用于查找拥有相似特征的用户。价值导向型来源包含客户终生价值，可用于创建与最有价值客户类似的受众。">
                            <i class="el-icon-warning" slot="reference"></i>
                        </el-popover>
                    </h3>
                    <el-select v-model="source" 
                        clearable  
                        filterable
                        value-key='index'
                        placeholder="选择类似受众来源">
                        <el-option
                            v-for="(item,index) in lookalikes"
                            :key="index"
                            :label="item.name"
                            :value="item">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="saRigBox">
                <span class="serial">2</span>
                <div>
                    <h3>选择受众所在地区</h3>
                    <el-select v-model="area"
                        clearable  
                        filterable
                        @change='f_countrys'
                        placeholder="选择受众所在地区">
                        <el-option
                            v-for="(item,index) in countrys"
                            :key="index"
                            :label="item.name"
                            :value="item.key">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="saRigBox">
                <span class="serial">3</span>
                <div>
                    <h3>选择受众规模</h3>
                    <div class="chooseNum">
                        <p>类似受众数量</p>
                        <el-popover
                            placement="right"
                            width="300"
                            trigger="hover">
                            <span>你可以使用相似程度不同的来源受众创建多个类似受众。这样你就可以为转化价值不同的受众设置不同竞价。</span>
                            <br>
                            <a style="color:#1f8bf8" href="">详细了解</a>
                            <i class="el-icon-warning" slot="reference"></i>
                        </el-popover>
                         <select autofocus v-model="seleVal" >
                            <option
                                v-for="item in 1"
                                :key="item"
                                :value="item">
                                {{item}}
                            </option>
                        </select>
                    </div>
                    <el-slider
                        v-model="scale"
                        range
                        :marks="marks"
                        show-stops
                        :max="10">
                    </el-slider>
                    <p class="sliderTip">你可以选择指定地区总人口的 1% 到 10% 作为广告受众规模。1% 的类似受众由与类似受众来源最相似的用户组成。提高百分比会创建规模更大、更广泛的受众。</p>
                    <div class="prospect"  v-if="source && area != '' ">
                        <div>
                            <h4>类似受众名称</h4>
                            <el-popover
                                placement="right"
                                width="300"
                                trigger="hover">
                                <span>类似受众名称包含你选择的地区和受众规模，以及类似受众的创建来源。这有助于你稍后查找正确的类似受众。</span>
                                <i class="el-icon-warning" slot="reference"></i>
                            </el-popover>
                            <p>{{area }}的 {{scale[0]}}% -  {{scale[1]}}% - {{source.name}} 按停留时间搜索访客</p>
                        </div>
                        <div>
                            <div>预估覆盖人数</div>
                            <p>{{estimate_dau*(scale[1]-scale[0])}} - {{estimate_mau*(scale[1]-scale[0])}}人</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sAFooter">
            <el-button plain size="medium" @click='f_clear' >取消</el-button>
            <el-button type="primary" size="medium" :disabled='!(area != "" && source)' @click='f_create'>创建</el-button>
            <span v-if="!(source && area != '')">来源或地区不能为空</span>
        </div>
    </div>
</template>

<script>
import * as API from '@/api/customAudience.js'
import { mapGetters, mapState } from 'vuex'
export default {
    props:{
        data_obj:{
            type:Object,
            default:()=>{
                return {}
            }
        },
    },
    data(){
        return{
            shrink:true,
            source:null,//来源
            area:'',//地区
            seleVal:'1',
            scale:[0,1],
            estimate_dau:'',
            estimate_mau:'',
            lookalikes:[],
            countrys:[],
            sliders:[],
            loading:false,
            deaSliders:[],
            marks:{
                0: '0%',
                1: '1%',
                2: '2%',
                3: '3%',
                4: '4%',
                5: '5%',
                6: '6%',
                7: '7%',
                8: '8%',
                9: '9%',
                10: {
                    style: {
                        width:'30px',
                    },
                    label:'10%'
                },
            },
        }
    },
    computed:{
        ...mapGetters(['initData'])
    },
    mounted(){
        this.f_getLookalikeSources()
        this.countrys=this.data_obj.TargetingCountry
    },
    methods:{
        f_countrys(value){
            if(value){
                this.deliveryEstimateAndSentence(value)
            }
        },
        f_shrink(){
            this.$nextTick(()=>{
                this.shrink = !this.shrink
            })
        },
        f_clear(){
            this.source=null
            this.area=''
            this.$emit('f_clear','')
        },
        f_getLookalikeSources(){
            API.lookalikeSources({accountId:this.data_obj.accountId}).then(res => {
                res.data.forEach((item,index) => {
                    item.index= index
                })
                this.lookalikes=res.data
            })
        },
        deliveryEstimateAndSentence(value){
            let params={
                geo_locations:[value],
                location_types:"all",
                genders:["F","M"]
            }
            API.deliveryEstimateAndSentence({accountId:this.data_obj.accountId,objective:"OUTCOME_SALES",targeting:params}).then(res => {
                this.estimate_dau=res.data.estimate_dau
                this.estimate_mau=res.data.estimate_mau
            })
        },
        f_create(){
            this.loading=true
            let spec=this.area
            let scaleName=this.scale[0]>0?this.scale[0]+'% to '+this.scale[1]+'%':this.scale[1]+'%'
            let obj={}
            if(this.source.type.key == 'custom_ratio'){
                obj={
                    subtype:"LOOKALIKE",
                    name:'类似受众('+spec+','+scaleName+')-'+this.source.name,
                    origin_audience_id:this.source.id,
                    lookalike_spec:{
                        type:'custom_ratio',
                        location_spec:{
                            geo_locations:{
                                countries:[spec]
                            },
                        },
                        is_financial_service: false,
                        allow_international_seeds: true,
                        // starting_ratio:this.scale[0]/100,
                        ratio:(this.scale[1]-this.scale[0])/100
                    }
                }
            }else if(this.source.type.key == 'page_like'){
                obj={
                    subtype:"LOOKALIKE",
                    name:'类似受众('+spec+','+scaleName+')-'+this.source.name,
                    // id:this.source.id,
                    lookalike_spec:{
                        country:spec,
                        starting_ratio:this.scale[0]/100,
                        ratio:(this.scale[1]-this.scale[0])/100,
                        page_id:this.source.id,
                        allow_international_seeds:true,
                        conversion_type:"page_like"
                    }
                }
            }
            API.createCustomaudience({accountId:this.data_obj.accountId,audience:JSON.stringify(obj)}).then(res => {
                if(res.code === 0 ){
                    this.$message({
                        type: "success",
                        message: `创建成功`
                    });
                    this.f_clear()
                }else{
                    this.$message({
                        type: "info",
                        message: `创建失败`
                    });
                }
                this.loading=false
                this.$emit('f_back','')
            }).catch(err => {console.log(err);this.loading=false})
        }
    }
}
</script>

<style scoped lang='scss'>
    .similarAudience{
        overflow: hidden;
        width: 100%;
        display: flex;
        position: relative;
        padding-bottom: 50px;
        .saLeft{
            position: relative;
            transition: all 0.1s linear;
            flex-basis:400px !important;
            background: rgb(245, 246, 247);
            padding:40px 20px 20px 20px;
            display: block;
            height: 580px;
        }
        .spread{
            margin-left: -340px;
        }
        .harvest{
            margin-left: 0;
        }
        .unwind{
            position: absolute;
            right: -10px;
            cursor: pointer;
            top: 10px;
            width: 20px;
            text-align: center;
            border-radius: 5px 20px 20px 5px;
            box-sizing: border-box;
            background: #fff;
            border: 1px solid #aaa;
            i{
                font-weight: 600;
            }
        }
        .shrink{
            position: absolute;
            right: 0;
            cursor: pointer;
            top: 10px;
            width: 20px;
            text-align: center;
            border-radius: 20px 5px 5px 20px;
            box-sizing: border-box;
            background: #fff;
            border: 1px solid #bbb;
            i{
                font-weight: 600;
            }
        }
        .leftTip{
            border: 1px dashed #999;
            border-radius: 5px;
            padding: 40px 20px 10px 20px;
            box-sizing: border-box;
            position: relative;
            h4{
                color: #000;
                position: absolute;
                top: -15px;
                left: 15px;
                width: 120px;
                padding: 10px 10px 0 10px;
                box-sizing: border-box;
                background: rgb(245, 246, 247);
                font-size: 13px;
            }
            h3{
                color: #333;
                font-weight: 600;
                font-size: 14px;
            }
            p{
                font-size: 12px;
                line-height: 20px;
            }
            a{
                color:#1f8bf8;
                font-size: 12px;
                padding: 5px 0 10px 0;
                display: block;
            }
        }
        .saRight{
            overflow: hidden;
            padding: 40px 0 10px 20px;
            box-sizing: border-box;
            flex-grow:1;
            height: 580px;
            i{
                color: #999;
            }
            .saRigBox{
                width: 100%;
                display: flex;
                margin-bottom: 20px;
                >div{
                    width: 92%;
                }
            }
            h3{
                font-weight: 600;
            }
            .serial{
                border-radius: 50%;
                display: inline-block;
                border: 2px solid #1f8bf8;
                width: 25px;
                height: 25px;
                text-align: center;
                line-height: 20px;
                color: #1f8bf8;
                margin-right: 20px;
            }
            .el-select{
                width: 100%;
                margin: 10px 0;
            }
        }   
        .chooseNum{
            display: flex;
            align-items: center;
            padding: 10px 0;
            select{
                width: 40px;
                outline: none;
                cursor: pointer;
                border: 1px solid #ddd;
            }  
            >span{
                padding: 0 5px;
                
            }
        }
        ::v-deep .el-slider{
            width: 99%;
        }
        .sliderTip{
            margin: 20px 0;
            color: #999;
        }
        .prospect{
            display: flex;
            h4{
                display: inline;
                padding-right: 10px;
            }
            >div{
                min-width: 300px;
            }
            >div:nth-of-type(1){
                margin-right: 50px;
            }
            p{
                padding: 10px 0;
            }
        }
        .sAFooter{
            position: absolute;
            border-top: 1px solid #eee;
            padding-top: 10px;
            bottom: 0px;
            width: 100%;
            left: 0;
            padding-left: 100px;
            box-sizing: border-box;
            text-align: center;
            button{
                margin: 0 10px 0 0;
            }
            span{
                color: red;
            }
        }
    }
</style>