<template>
  <div class="my_tip">
    <el-dialog :title="myDialogObj.title||'提示'"
        :top="myDialogObj.top || '2vh !important' "
        :visible.sync="myDialogObj.dialogVisible"
        :width="myDialogObj.width||'1000px'" 
        append-to-body 
        :close-on-click-modal="false" 
        @close="f_clear" 
        :custom-class="customClass||''"
        center>
            <slot></slot> 
    </el-dialog>
  </div>
</template>

<script>
export default {
    components:{},
    props:{
        myDialogObj: {
            type: Object,
            default:() => {},
        },
        customClass:{
            type:String,
            default:''
        }
    },
    data(){
        return {
          
        }
    },
    computed:{},
    watch:{},
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        f_clear () {
            console.log(1);
            this.$emit('clear', false);
        },
        f_sure () {
            this.$emit('sure', false);
        }
    },
}
</script>

<style lang="scss" scoped>
    ::v-deep .el-dialog{
        // width: 560px;
        position: absolute;
        left: 50%;
        top: 10vh !important;
        transform: translate(-50%,0) !important;
        .el-dialog__header{
            border-bottom: 1px solid #E4E4E4;
            .el-dialog__title{
                color: #333 ;
            }
            .el-dialog__headerbtn{
              .el-dialog__close{
                color: #666666;
                font-weight: bold;
                font-size: 16px;
              }  
            }
        }
        .el-dialog__footer{
            border-top: 1px solid #E4E4E4;
        }
    }
</style>