<template>
  <div class="tableWrap">
    <div class="table-top-bar" >
      <el-dropdown  size="small" @command="f_handleCommand">
        <el-button type="primary" size="small">
          创建受众<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="self" @click.native="f_audience">自定义受众(网站)</el-dropdown-item>
          <!-- <el-dropdown-item command="customName" @click.native="f_audience">自定义受众(客户名单)</el-dropdown-item> -->
          <el-dropdown-item command="similer" @click.native="f_audience">类似受众</el-dropdown-item>
          <el-dropdown-item command="category" @click.native="f_audience">品类自定义受众</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
        <my-table
          :tableHead='tableHead'
          :tableData='tableData'
          @load='f_load'
          @f_cellMouseEnter='f_cellMouseEnter'
          @f_cellMouseLeave='f_cellMouseLeave'
        >
          <template #nameSlot="{scope}">
            <div class="listDetails">
                <div>
                  <span>{{ scope.row.name}}</span>
                  <span class="deaname">{{ scope.row.description}}</span>
                </div>
                <div class="isShow" v-show="scope.row.isShow">
                  <template v-if="scope.row.permission_for_actions.supports_recipient_lookalike">
                    <i class="el-icon-user" v-show='!scope.row.similarShow && scope.row.subtype != "LOOKALIKE"' @mouseenter='f_similarShow(scope.row,1)'></i>
                    <span  @mouseleave='f_similarHide(scope.row,1)'>
                      <el-button size="mini" plain v-show='scope.row.similarShow' @click="f_audience('similer')">类似受众</el-button>
                    </span>
                  </template>
                  <i class="el-icon-view" v-show='!scope.row.deaShow && scope.row.subtype != "LOOKALIKE"' @mouseenter='f_similarShow(scope.row,2)'></i>
                  <span  @mouseleave='f_similarHide(scope.row,2)'>
                    <el-button size="mini" plain v-show='scope.row.deaShow && scope.row.subtype != "LOOKALIKE"' @click="f_getDetails(scope.row)">受众详情</el-button>
                  </span>
                  <template v-if="scope.row.permission_for_actions.can_share">
                    <i class="el-icon-share" v-show='!scope.row.shareShow && scope.row.subtype != "LOOKALIKE"' @mouseenter='f_similarShow(scope.row,3)'></i>
                    <span  @mouseleave='f_similarHide(scope.row,3)'>
                      <el-button size="mini" plain v-show='scope.row.shareShow' @click="f_audience('share',scope.row)">共享受众</el-button>
                    </span>
                  </template>
                </div>
              </div>
          </template>
          <template #typeSlot="{scope}">
            <span>{{scope.row.subtype =="WEBSITE"?'自定义受众':'类似受众'}}</span>
            <p class="common">{{scope.row.subtype == 'WEBSITE'?'网站':scope.row.subtype == 'LOOKALIKE'?'':scope.row.subtype == 'CUSTOM'?'客户名单':scope.row.subtype}}</p>
          </template>
          <template #availabilitySlot="{scope}">
            <span :style="{fontSize:'13px',whiteSpace: 'pre-wrap',paddingBottom:'5px'} ">{{scope.row.operation_status.description}}</span>
            <p class="common">最新编辑：{{scope.row.time_created | manageData}}</p>
          </template>
          <template #scaleSlot="{scope}">
            <span >{{scope.row.approximate_count_upper_bound > 1000 ? scope.row.approximate_count_lower_bound+'-'+scope.row.approximate_count_upper_bound :'1000以下'}}</span>
          </template>
          <template #dataSlot="{scope}">
            <span>{{scope.row.time_created | manageData1}}</span>
            <span :style="{fontSize:'12px',color:'#999'} "> {{scope.row.time_created | manageData2}}</span>
          </template>
        </my-table>
      </div>
        <myDialog :myDialogObj="myDialogObj" @clear="f_clear" v-if="myDialogObj.dialogVisible" :customClass="commandType=='self'?'self-dialog':''">
            <similaRaudience @f_clear='f_clear'  :data_obj='data_obj' @f_back='f_back' v-if="commandType=='similer'"></similaRaudience>
            <self-audience v-if="commandType=='self'" @back='f_back' :detailsData="detailsData" :accountId='data_obj.accountId'  @clear='f_clear'></self-audience>
            <category-audience v-if="commandType=='category'" @clear='f_clear' @back='f_back' :data_obj='data_obj'></category-audience>
            <share-audience v-if="commandType=='share'" @clear='f_clear' @back='f_back' :audience_id="audience_id"></share-audience>
            <custom-name v-if="commandType=='customName'" @clear='f_clear' @back='f_back' :data_obj='data_obj' :audience_id="audience_id"></custom-name>
        </myDialog>
  </div>
</template>

<script>
import {adConstsMap} from '@/api/creatAd.js'
import * as API from '@/api/customAudience.js'
import myTable from './myTable.vue'
import myDialog from '../myDialog.vue'
import similaRaudience from '../similaRaudience.vue'
import selfAudience from '../../selfAudience/index.vue'
import categoryAudience from '../../categoryAudience/index.vue'
import shareAudience from '../../shareAudience/share.vue'
import customName from '../../customName/index.vue'
export default {
  name: "AdTable",
  components:{
      myTable,
      myDialog,
      similaRaudience,
      selfAudience,
      categoryAudience,
      shareAudience,
      customName
  },
  data() {
      return {
        detailsData:{},
        commandType:'similer',
        myDialogObj:{
          title:'创建类似受众',
          dialogVisible:false,
          top:'2vh !important'
        },
        tableHead:[],
        tableData: [],
        data_obj:{
          accountId:'',
          TargetingCountry:[]
        },
        page:{
          after:'',
          limit:'',
        },
        showLoad:false,
        audience_id:null
      }
  },
  filters: {
    manageData(value){
      let data= new Date(value*1000)
      let month = data.getMonth()+1
      let day  = data.getDate()
      let h = data.getHours()
      let mm = data.getMinutes()
      if(month<10){
        month='0'+month
      }
      if(day<10){
        day='0'+day
      }
      if(h<10){
        h='0'+h
      }
      if(mm<10){
        mm='0'+mm
      }
      return month+'-'+day+' '+h+':'+mm
    },
    manageData1(value){
        let data= new Date(value*1000)
        let month = data.getMonth()+1
        let day  = data.getDate()
        if(month<10){
          month='0'+month
        }
        if(day<10){
          day='0'+day
        }
        return month+'-'+day
    },
    manageData2(value){
        let data= new Date(value*1000)
        let h = data.getHours()
        let mm = data.getMinutes()
        if(h<10){
          h='0'+h
        }
        if(mm<10){
          mm='0'+mm
        }
        return h+':'+mm
    }
  },
  computed: {
    accountId() {
      return this.$store.getters.accountId;
    },
  },
  watch: {},
  mounted(){
    let a=JSON.parse(localStorage.getItem('activeAdAccount'))
    this.data_obj.accountId = this.accountId;
    console.log(this.data_obj);
    this.tableHead=[
      { prop: "name", label: "名称",width:700,slot:"nameSlot"},
      { prop: "subtype", label: "类型",minWidth:200,slot:"typeSlot"},
      { prop:'approximate_count_upper_bound',minWidth:200,label: "规模",slot:"scaleSlot"},
      { prop: "availability", label: "可用性",width:280,slot:"availabilitySlot"},
      { prop: "time_created", label: "创建日期",width:200,slot:"dataSlot"},
    ]
    this.getInitData()
  },
  methods:{
    f_load(){
      this.f_getTable(this.page.after)
    },
    // 详情展示
    f_getDetails(row){
      console.log(row)
      this.detailsData = row
      this.commandType ='self'
      this.myDialogObj.title = '自定义受众详情'
      this.myDialogObj.dialogVisible=true
    },
    // 获取广告创建常量
    async getInitData(){
      await adConstsMap().then(res=>{
        this.$store.commit("initData/setInitData", res.data);
        this.data_obj.TargetingCountry = res.data.targeting.TargetingCountry;
      });
    },
    // 下拉选项
    f_handleCommand(command) {
      this.commandType = command
    },
    //鼠标进去
    f_cellMouseEnter(row, column, cell, event){
      this.$nextTick(()=>{
        row.isShow = !row.isShow
      })
    },
    //鼠标进去
    f_cellMouseLeave(row, column, cell, event){
       this.$nextTick(()=>{
        row.isShow = !row.isShow
      })
    },
    f_similarShow(row,num){
      if(num ==1){
        this.$nextTick(()=>{
          row.similarShow = !row.similarShow
        })
      }else if(num ==2){
        this.$nextTick(()=>{
          row.deaShow = !row.deaShow
        })
      }else if(num == 3){
        this.$nextTick(()=>{
          row.shareShow = !row.shareShow
        })
      }
    },
    f_similarHide(row,num){
      if(num == 1){
        this.$nextTick(()=>{
          row.similarShow = !row.similarShow
        })
      }else if(num == 2){
        this.$nextTick(()=>{
          row.deaShow = !row.deaShow
        })
      }else if(num == 3){
        this.$nextTick(()=>{
          row.shareShow = !row.shareShow
        })
      }
    },
    //打开弹窗
    f_audience(type,row){
      if(type == 'similer'){
        this.commandType='similer'
      }
      if(type == 'share'){
        this.commandType='share';
        this.audience_id = row.id
      }
      if(this.commandType=='self') this.myDialogObj.title = '创建网站自定义受众'
      if(this.commandType=='similer')  this.myDialogObj.title = '创建类似受众'
      if(this.commandType=='category')  this.myDialogObj.title = '创建品类自定义受众'
      if(this.commandType=='share')  this.myDialogObj.title = '创建共享受众'
      if(this.commandType=='customName')  this.myDialogObj.title = '创建客户名单共享受众'
      this.myDialogObj.dialogVisible=true
    },
    //关闭弹窗
    f_clear(){
       this.myDialogObj.dialogVisible=false
       this.commandType=='similer'
       this.detailsData = {}
    },
    f_back(){
      console.log(1);
      this.page.after='',
      setTimeout(()=>{
        this.f_getTable(this.page.after,true)
      },500)
      // this.f_getTable(this.page.after,true)
    },
    //获取列表
    async f_getTable(after='',back=false){
      let params={
        accountId:this.data_obj.accountId,
        paging:{
          after:after,
          limit:10
        }
      }
      await API.listCustomerAudience(params).then(res => {
        if(res.data.list && res.data.list.length>0){
          res.data.list.forEach( item => {
            item.isShow=false,
            item.similarShow=false,
            item.deaShow=false,
            item.shareShow=false
          })
          if(back){
            console.log('走的back');
            this.$nextTick(()=> {
              this.tableData=res.data.list
            })
          }else{
            this.tableData.push(...res.data.list) 
          }
          this.page=res.data.page
        }
      }).catch(err => {
        console.log(err);
      })
      
    }
  }
};
</script>


<style lang="scss">
.tableWrap {
  .el-table .cell {
    line-height: unset;
  }
}
// 状态
.header-status {
  .cell {
    display: flex !important;
    align-items: center;
  }
  .caret-wrapper {
    top: 2px;
    
  }
}
.my-table.el-table .el-table__body-wrapper {
  height: calc(100vh - 300px) ;
  overflow: auto;
}
</style>
<style lang="scss" scoped>
.tableWrap {
  padding: 16px;
  padding-top: 0;

  .loadMore {
    text-align: center;
    line-height: 28px;
    color: #999;
  }
  ::v-deep .el-dropdown{
    margin: 10px 0;
  }
  ::v-deep .tableRow{
    height: 80px;
  }
  .listDetails{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .deaname{
    color: #999;
    padding-left: 20px;
    font-size: 12px;
  }
  .isShow{
    i{
      font-size: 14px;
      padding: 0 10px;
      cursor: pointer;
      color: #409EFF;
    }
    button{
      color: #409EFF;
      border-color:#409EFF ;
    }
  }
  .common{
    color: #999;
    font-size: 12px;
  }
}
</style>
<style lang="scss">
.self-dialog{
  position: relative;
  .el-dialog__body{
    padding: 0 !important;
    max-height: 75vh;
    overflow: auto;
  }
  .dialog-footer{
    border-top: 1px solid #E4E4E4;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 10px;
  }
}
</style>