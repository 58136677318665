<template>
  <div>
    <div class="customName">
      <el-form
        size="small"
        :model="form"
        :rules="formRules"
        ref="formRef"
        label-width="100px"
      >
        <el-form-item label="下载模板：" prop="download">
          <span class="download">客户名单模板.xlsx</span
          ><el-link
            @click="handleDownload"
            icon="el-icon-download"
            :underline="false"
          ></el-link>
        </el-form-item>
        <el-form-item label="上传文件：" prop="file">
          <el-upload
            action="#"
            accept=".xls,.xlsx"
            :file-list="fileList"
            :on-remove="handleRemove"
            :on-change="changeFile"
            :auto-upload="false"
          >
            <el-button size="small" type="primary">点击上传</el-button><a
            :href="downloadUrl"
            download="customAudienceHelp.docx"
            style="font-size:12px;margin-left:5px;color:#999"
          >(查看帮助)</a>
            <div slot="tip" class="el-upload__tip">
              只能上传excel/cvs文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="受众名称：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="受众描述：" prop="description">
          <el-input v-model="form.description" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancle">取 消</el-button>
        <el-button type="primary" size="small" @click="handleConfirm">创 建</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/customAudience.js";
export default {
  data() {
    var validateFile = (rule, value, callback) => {
        if (!this.fileList.length) {
          callback(new Error('请选择文件'));
        } else {
          callback();
        }
      };
    return {
      fileList: [],
      formRules: {
        name:[{required:true,message:'请填写受众名称',trigger:'blur'}],
        file:[{required:true, validator:validateFile,trigger:'blur'}]
      },
      form:{},
      downloadUrl:`${process.env.BASE_URL}/fileSource/customAudienceHelp.docx`
    };
  },
  computed: {
    accountId() {
      return this.$store.getters.accountId;
    },
  },
  methods: {
    cancle() {
      this.$emit("clear");
    },
    handleConfirm() {
      //  this.$emit("clear");
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          let formData = new FormData();
          console.log(this.fileList);
          formData.append('file',this.fileList[0].raw);
          formData.append('accountId',this.accountId);
          formData.append('name',this.form.name);
          formData.append('description',this.form.description);
          this.$showLoading();
          API.createCustomNameAudience(formData).then(res=>{
            this.$hideLoading();
            if (res.code == 0) {
                this.form={};
                this.fileList = [];
                this.$message({
                  type: "success",
                  message: "导入成功",
                });
                this.cancle()
                this.$emit("back");
                // this.$emit("close-dialog");
                // this.$emit("getList");
            }
          })
          // billCheck({ bill: { ...this.formModel, billType: "REBATE" } }).then(
          //   (res) => {
          //     this.$hideLoading();
          //     if (res.code == 0) {
          //       this.formModel = {
          //         agency: null,
          //         platform: null,
          //         month: "",
          //         name: "",
          //         area: "",
          //       };
          //       this.fileList = [];
          //       this.$message({
          //         type: "success",
          //         message: "导入成功",
          //       });
          //       this.$emit("close-dialog");
          //       this.$emit("getList");
          //     }
          //   }
          // );
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    changeFile(file, fileList){
      this.fileList = [file]
    },
    handleDownload() {
      window.open(API.downloadExcel);
    },
    download(){
      window.open('./自定义受众模板导入说明.docx')
    }
  },
};
</script>

<style lang="scss">
.customName{
  width: 60%;
  .dialog-footer {
    text-align: right;
    padding-top: 15px;
  }
  .download{
    margin-right: 10px;
  }
  .el-upload-list__item{
    transition: none;
  }
}
</style>